import {
  EventAction,
  GridTextWithIconModel,
  MemberPermissions,
} from '@customer-portal/shared';

export interface SettingsMembersItemModel {
  name: string;
  email: string;
  company: string;
  services: string;
  sites: string;
  access: string;
  isDisabled: boolean;
  eventActions?: EventAction;
  iconTooltip?: GridTextWithIconModel;
}

export interface SettingsNewMemberFormModel {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export interface MemberAreasPermissions {
  area: string;
  permission: MemberPermissions;
}

export enum MemberAreaPermissions {
  Contracts = 'contracts',
  ScheduleAuditsFindings = 'scheduleAuditsFindings',
  Certificates = 'certificates',
  Financials = 'financials',
}

export enum AccessAreaRoleNames {
  CONTRACTS_VIEW = 'Contracts_View',
  SAF_VIEW = 'SAF_View',
  SAF_EDIT = 'SAF_Edit',
  CERTIFICATES_VIEW = 'Certificates_View',
  CERTIFICATES_EDIT = 'Certificates_Edit',
  FINANCIALS_EDIT = 'Financials_Edit',
}

export interface CreateContactAccessArea {
  roleId: number;
  roleName: string;
}

export interface CreateContactCompany {
  companyId: number;
  companyName: string;
}

interface CreateContactService {
  serviceId: number;
  serviceName: string;
}

interface CreateContactSite {
  siteId: number;
  siteName: string;
}

interface CreateContactCity {
  cityName: string;
  sites: CreateContactSite[];
}

interface CreateContactCountry {
  countryId: number;
  countryName: string;
  cities: CreateContactCity[];
}

export interface CreateContactRequest {
  email: string;
  firstName: string;
  lastName: string;
  communicationLanguage: string;
  phone: string;
  countryId: number;
  jobTitle: string;
  isAdmin: number;
  accessAreas: CreateContactAccessArea[];
  companies: CreateContactCompany[];
  services: CreateContactService[];
  countries: CreateContactCountry[];
}
