import { gql } from 'apollo-angular';

export const SETTINGS_MEMBERS_PERMISSIONS_QUERY = gql`
  query GetSettingsMembersPermissions($memberEmail: String) {
    getUserPermissions(memberEmail: $memberEmail) {
      data {
        companies {
          companyId
          companyName
          services {
            countries {
              cities {
                cityName
                sites {
                  siteId
                  siteName
                }
              }
              countryId
              countryName
            }
            serviceId
            serviceName
          }
        }
        userId
      }
      errorCode
      isSuccess
      message
    }
  }
`;
