import { Language } from '@customer-portal/shared';

import { ProfileLanguageDto } from '../../dtos';

export class ProfileLanguageMapperService {
  static mapToProfileLanguageModel(dto: ProfileLanguageDto): Language {
    const languageKeys = Object.keys(Language) as string[];
    const portalLanguage = dto?.data?.portalLanguage;
    const isSupportedLanguage =
      portalLanguage && languageKeys.includes(portalLanguage);

    return isSupportedLanguage
      ? Language[portalLanguage as keyof typeof Language]
      : Language.English;
  }
}
