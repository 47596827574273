import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EMPTY, map, Observable } from 'rxjs';

import {
  ADD_NEW_MEMBER_MUTATION,
  SETTINGS_MEMBERS_PERMISSIONS_QUERY,
} from '../../graphql';
import { CreateContactRequest } from '../../models';

@Injectable({ providedIn: 'root' })
export class SettingsMembersPermissionsService {
  private clientName = 'contact';

  constructor(private readonly apollo: Apollo) {}

  getSettingsMembersPermissions(memberEmail: string): Observable<any> {
    return this.apollo
      .use(this.clientName)
      .query({
        query: SETTINGS_MEMBERS_PERMISSIONS_QUERY,
        variables: {
          memberEmail,
        },
      })
      .pipe(map((results: any) => results?.data?.getUserPermissions));
  }

  createNewMember(memberDetails: CreateContactRequest): Observable<any> {
    return this.apollo
      .use(this.clientName)
      .mutate({
        mutation: ADD_NEW_MEMBER_MUTATION,
        variables: {
          createContactRequest: memberDetails,
        },
      })
      .pipe(map((results: any) => results?.data?.createContactDetails));
  }

  removeMember(_memberEmail: string): Observable<any> {
    // TODO: Implement removeMember mutation
    return EMPTY;
  }
}
