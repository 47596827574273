import { TreeNode } from 'primeng/api';

import {
  COLUMN_DELIMITER,
  CompanyModel,
  GridEventActionType,
  SiteModel,
} from '@customer-portal/shared';

import {
  City,
  Country,
  SettingsAdminListDto,
  SettingsAdminListItemDto,
  SettingsMembersListDto,
  SettingsMembersListItemDto,
  SettingsMembersPermissionsDto,
} from '../../dtos';
import { CreateContactCompany, SettingsMembersItemModel } from '../../models';

const extractUniqueSiteNames = (countries: Country[]): string[] => {
  const siteNamesSet = new Set<string>();

  countries.forEach((country) => {
    country.cities.forEach((city) => {
      city.sites.forEach((site) => {
        siteNamesSet.add(site.siteName);
      });
    });
  });

  return Array.from(siteNamesSet);
};

const extractUniqueCompanyNames = (companies: CompanyModel[]) =>
  Array.from(new Set(companies.map((company) => company.companyName)));

const isUserPendingVerification = (userStatus: string) =>
  userStatus.toLowerCase() === 'in progress';

const createNode = (
  data: string | number,
  label: string,
  key: string,
  children: TreeNode[] = [],
): TreeNode => ({
  data,
  label,
  key,
  children,
});

const mapSitesToNodes = (sites: SiteModel[]): TreeNode[] =>
  sites.map((site) => {
    const siteNode = createNode(
      site.siteId!,
      site.siteName,
      `${site.siteId!}-${site.siteName}`,
    );

    return siteNode;
  });

const mapCitiesToNodes = (cities: City[]): TreeNode[] =>
  cities.map((city) => {
    const cityNode = createNode(
      city.cityName,
      city.cityName,
      `${city.cityName}-city`,
    );
    cityNode.children = mapSitesToNodes(city.sites);

    return cityNode;
  });

const mapCountriesToNodes = (countries: Country[]): TreeNode[] =>
  countries.map((country) => {
    const countryNode = createNode(
      country.countryId,
      country.countryName,
      `${country.countryId}-${country.countryName}`,
    );
    countryNode.children = mapCitiesToNodes(country.cities);

    return countryNode;
  });

export class SettingsMembersMapper {
  static mapToMembersList(
    dto: SettingsMembersListDto,
    isCurrentUserAdmin: boolean,
  ): SettingsMembersItemModel[] {
    if (!dto?.data) {
      return [];
    }

    const { data } = dto;

    return data.map((member: SettingsMembersListItemDto) => {
      const services = Array.from(
        new Set(
          member.services.map((auditService) => auditService.serviceName),
        ),
      ).join(COLUMN_DELIMITER);

      const companies = extractUniqueCompanyNames(member.companies).join(
        COLUMN_DELIMITER,
      );

      const sites = extractUniqueSiteNames(member.countries).join(
        COLUMN_DELIMITER,
      );

      const { name, email, userStatus, roles: access } = member;

      return {
        name,
        email,
        company: companies,
        services,
        sites,
        access,
        iconTooltip: {
          displayIcon: isUserPendingVerification(userStatus),
          iconClass: 'pi pi-info-circle',
          iconPosition: 'suffix',
          tooltipMessage: 'settings.membersTab.pendingVerification',
        },
        isDisabled: isUserPendingVerification(userStatus),
        eventActions:
          !isUserPendingVerification(userStatus) && isCurrentUserAdmin
            ? {
                id: email,
                actions: [
                  {
                    label: GridEventActionType.ManagePermissions,
                    i18nKey: `gridEvent.${GridEventActionType.ManagePermissions}`,
                    icon: 'pi pi-lock',
                    disabled: true,
                  },
                  {
                    label: GridEventActionType.Remove,
                    i18nKey: `gridEvent.${GridEventActionType.Remove}`,
                    icon: 'pi pi-trash',
                    disabled: false,
                  },
                ],
              }
            : undefined,
      };
    });
  }

  static mapToAdminList(
    dto: SettingsAdminListDto,
    isCurrentUserAdmin: boolean,
  ): SettingsMembersItemModel[] {
    if (!dto?.data) {
      return [];
    }

    const { data } = dto;

    return data.map((admin: SettingsAdminListItemDto) => {
      const company = extractUniqueCompanyNames(admin.companies).join(
        COLUMN_DELIMITER,
      );

      const { name, email, userStatus, isCurrentUser } = admin;

      // TODO: translate the strings
      return {
        name,
        email,
        company,
        services: 'All Services',
        sites: 'All Sites',
        access: 'Admin',
        isDisabled: isUserPendingVerification(userStatus),
        iconTooltip: {
          displayIcon: isUserPendingVerification(userStatus),
          iconClass: 'pi pi-info-circle',
          iconPosition: 'suffix',
          tooltipMessage: 'settings.membersTab.pendingVerification',
        },
        eventActions:
          !isUserPendingVerification(userStatus) &&
          !isCurrentUser &&
          isCurrentUserAdmin
            ? {
                id: email,
                actions: [
                  {
                    label: GridEventActionType.ManagePermissions,
                    i18nKey: `gridEvent.${GridEventActionType.ManagePermissions}`,
                    icon: 'pi pi-lock',
                    disabled: true,
                  },
                  {
                    label: GridEventActionType.Remove,
                    i18nKey: `gridEvent.${GridEventActionType.Remove}`,
                    icon: 'pi pi-trash',
                    disabled: false,
                  },
                ],
              }
            : undefined,
      };
    });
  }

  static mapToMemberCompanies(dto: SettingsMembersPermissionsDto): TreeNode[] {
    return dto.data.flatMap((data) =>
      data.companies.map((company) => ({
        data: company.companyId,
        label: company.companyName,
        key: `${company.companyId}-${company.companyName}`,
        children: [],
      })),
    );
  }

  static mapToMemberServices(
    dto: SettingsMembersPermissionsDto,
    selectedCompanyIds: number[],
  ): TreeNode[] {
    const services: TreeNode[] = [];

    dto.data.forEach((data) => {
      data.companies.forEach((company) => {
        company.services.forEach((service) => {
          if (selectedCompanyIds.includes(company.companyId)) {
            services.push({
              data: service.serviceId,
              label: service.serviceName,
              key: `${service.serviceId}-${service.serviceName}`,
              children: [],
            });
          }
        });
      });
    });

    return services;
  }

  static mapToMemberSites(
    dto: SettingsMembersPermissionsDto,
    selectedServiceIds: number[],
  ): TreeNode[] {
    return dto.data.flatMap((data) =>
      data.companies.flatMap((company) =>
        company.services.flatMap((service) =>
          selectedServiceIds.includes(service.serviceId)
            ? mapCountriesToNodes(service.countries)
            : [],
        ),
      ),
    );
  }

  static mapToSelectedCompanies = (
    dto: SettingsMembersPermissionsDto,
    selectedCompanyIds: number[],
  ): CreateContactCompany[] =>
    dto.data
      .flatMap((data) => data.companies)
      .filter((company) => selectedCompanyIds.includes(company.companyId))
      .map((company) => ({
        companyId: company.companyId,
        companyName: company.companyName,
      }));

  static mapToSelectedServices = (
    dto: SettingsMembersPermissionsDto,
    selectedServiceIds: number[],
  ): { serviceId: number; serviceName: string }[] =>
    dto.data
      .flatMap((data) => data.companies)
      .flatMap((company) => company.services)
      .filter((service) => selectedServiceIds.includes(service.serviceId))
      .map((service) => ({
        serviceId: service.serviceId,
        serviceName: service.serviceName,
      }));

  static mapToSelectedSites = (
    dto: SettingsMembersPermissionsDto,
    selectedSiteIds: (string | number)[],
  ): any[] =>
    dto.data
      .flatMap((data) => data.companies)
      .flatMap((company) => company.services)
      .flatMap((service) =>
        service.countries
          .map((country) => ({
            countryId: Number(country.countryId),
            countryName: country.countryName,
            cities: country.cities
              .map((city) => ({
                cityName: city.cityName,
                sites: city.sites.filter((site) =>
                  selectedSiteIds.includes(site.siteId!),
                ),
              }))
              .filter((city) => city.sites.length > 0),
          }))
          .filter((country) => country.cities.length > 0),
      );
}
