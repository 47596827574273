import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { Language } from '@customer-portal/shared';

import { LoadProfileLanguage, UpdateProfileLanguage } from '../actions';
import { ProfileLanguageSelectors } from '../selectors';

@Injectable()
export class ProfileLanguageStoreService {
  constructor(private store: Store) {}

  get languageLabel(): Signal<Language> {
    return this.store.selectSignal(ProfileLanguageSelectors.languageLabel);
  }

  @Dispatch()
  loadProfileLanguage = () => new LoadProfileLanguage();

  @Dispatch()
  updateProfileLanguage = (languageLabel: Language) =>
    new UpdateProfileLanguage(languageLabel);
}
