import { gql } from 'apollo-angular';

export const PROFILE_QUERY = gql`
  query GetProfile {
    userProfile {
      data {
        firstName
        lastName
        displayName
        country
        region
        email
        phone
        communicationLanguage
        jobTitle
        portalLanguage
      }
      isSuccess
      message
      errorCode
    }
  }
`;
