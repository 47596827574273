import { gql } from 'apollo-angular';

export const SETTINGS_ADMIN_LIST_QUERY = gql`
  query GetSettingsAdminList {
    adminList {
      data {
        name
        email
        userStatus
        isCurrentUser
        companies {
          companyId
          companyName
        }
      }
      isSuccess
      message
      errorCode
    }
  }
`;
